import { render, staticRenderFns } from "./Empty.vue?vue&type=template&id=4caa2be6&"
var script = {}
import style0 from "theme/css/main.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports